<template>
  <div v-loading="loading">
      <div class="card card-custom card-stretch gutter-b">
          <div class="card-header">
              <div class="card-title">
                    <span class="card-icon">
                        <i class="flaticon2-delivery-package text-primary"></i>
                    </span>
                  <h3 class="card-label"></h3>
              </div>
          </div>
          <div class="card-body">
               
          </div>
      </div>
  </div>
</template>

<script>
import apiService from "@/core/services/api.service";

export default {
    data() {
        return {
            listMaintain: [],
            listBug: [],
            loading: false
        }
    },
    created() {
        this.getListNotification()
    },
    methods: {
        getListNotification() {
            this.loading = false
        }
    }
};
</script>
